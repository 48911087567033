/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, SeparateLine, SeparateLineWrap, Fullmap, FullmapWrap, FullmapCover, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Potvrzení účasti"}>
        <SiteHeader />

        <Column className="--center pb--80 pt--80" name={"potvrdit-ucast-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper className="pt--40">
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"Potvrzení účasti ve Svinech<br>"}>
              </Title>

              <Text className="text-box text-box--center fs--22" content={"Dejte nám vědět, jestli přijmete naše pozvání a zúčastníte se naší svatby ve Svinech. <br>Budeme moc rádi, když nám napíšete i počet dospělých a dětí.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"potvrdit-ucast"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 ff--2 fs--14" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Jméno","type":"text","required":true,"placeholder":"Jméno"},{"name":"Email","type":"email","placeholder":"Email"},{"name":"Zúčastníte se naší svatby ve Svinech?","type":"select","options":["Ano","Ne"],"required":true},{"name":"Kolik Vás přijde?","type":"textarea","required":false},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"mzjve6f37n"} layout={"l6"}>
        </SeparateLine>


        <SeparateLine className="pb--10 pt--10" name={"je08apuxgy"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"nlsmqkbfo6s"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48" content={"Jak se k nám dostanete?<br>"}>
              </Title>

              <Text className="text-box text-box--center fs--20" style={{"maxWidth":1000}} content={"Adresa obecního hostince je <b>Sviny 17</b>. <br>Parkovat můžete přímo před hostincem nebo ve vedlejší ulici u kapličky. <br>Kdo bude chtít zajistit ubytování, zaparkuje přímo tam, kde bude spát. <br>My se Vám včas ozveme a domluvíme se na podrobnostech. <br><br>Nejlépe se k nám dostanete po dálnici D3 - Exit 107 směr Wien, Třeboň, Veselí n. Lužnící/Týn n. Vltavou. <br>Kdo nebude chtít jet po dálnici, souběžně vede silnice č. 603, po které se stejně jako po D3 dostanete přes Veselí n. Lužnící do Svinů. <br>Ve druhém směru, od Týna nad Vltavou, bude při příjezdu na náves hostinec přímo před Vámi. <br><br>Pro jistotu Vám ale přikládáme ještě mapu a obrázek, kde zaparkovat.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"a6swl9az44c"}>
          
          <FullmapWrap className="--full --center">
            
            <FullmapCover zoom={"11"} place={"Sviny 17"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--60 pt--60" name={"nnhx4g740mc"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32019/03af7bb675c14eb58e63d25f7c5a162f_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":881}} srcSet={"https://cdn.swbpg.com/t/32019/03af7bb675c14eb58e63d25f7c5a162f_s=350x_.png 350w, https://cdn.swbpg.com/t/32019/03af7bb675c14eb58e63d25f7c5a162f_s=660x_.png 660w, https://cdn.swbpg.com/t/32019/03af7bb675c14eb58e63d25f7c5a162f_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"jlcmhzwz0r"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32019/40bdaa9200804da4abf2d43fa44a73ad_e=0x0x1253x361_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":896}} srcSet={"https://cdn.swbpg.com/t/32019/40bdaa9200804da4abf2d43fa44a73ad_e=0x0x1253x361_s=350x_.png 350w, https://cdn.swbpg.com/t/32019/40bdaa9200804da4abf2d43fa44a73ad_e=0x0x1253x361_s=660x_.png 660w, https://cdn.swbpg.com/t/32019/40bdaa9200804da4abf2d43fa44a73ad_e=0x0x1253x361_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"wcbs01wlmx"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box fs--20" style={{"maxWidth":1000}} content={"Cestu jsme se Vám pokusili popsat co nejpodrobněji a věříme, že k nám trefíte. <br>Nejsme si totiž jisti, jestli budeme moci v den svatby zvednout telefony. Děkujeme za pochopení!<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"xq6yao7bot"} layout={"l6"}>
        </SeparateLine>


        <Column className="--center pb--80 pt--40" name={"paticka-2"}>
          
          <ColumnWrap className="column__flex --center el--1" anim={null} animS={null} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"Těšíme se na Vás!"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}